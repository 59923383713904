
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Head from "next/head";
import { useRouter } from "next/router";
import styles from "../styles/components/Home.module.scss";
import dynamic from "next/dynamic";
import React from "react";
import Common from "./../libs/Common";
import Cookies from "js-cookie";
import { AppContext } from "./../helpers/AppContext";
import serversideHome from "../serverside/Home";
import useTranslation from "next-translate/useTranslation";
import { getTrialDay } from "./../helpers/common";
import { getOrganizationSchema } from ".././helpers/common";
const HeroBanner = dynamic(() => import("../components/HeroBanner"));
const HeroVideo = dynamic(() => import("../components/HeroVideo"));
const Feature = dynamic(() => import("../components/Feature"));
const PressRelease = dynamic(() => import("../components/PressRelease"));
const Footer = dynamic(() => import("../components/Footer"));
const SchemaMarkup = dynamic(() => import("../components/SchemaMarkup"));

function Home(props) {
  const {
    subscriptions,
    userDetails,
    subscriptionInfo,
    fullUrl,
    signupBook,
    authKeys,
    post,
    origin,
    presReleases,
  } = props;
  const { t } = useTranslation();
  const day = getTrialDay(subscriptions);
  const router = useRouter();
  const { locale } = router;
  const url = `${origin}${fullUrl}`;
  const tempCode = locale.substring(0, 2).toUpperCase();
  const defaultImage = `${origin}/cdn/images/nextory-share-pink.jpg`;

  return (
    <>
      <Head>
        <title>{t(`lang_seo:generic.title`)} </title>
        <link rel="alternate" href={`${origin}`} hrefLang={tempCode} />
        <meta httpEquiv="Content-Language" content={locale} />
        <meta name="name" content={t(`lang_seo:generic.title`)} />
        <meta
          name="description"
          content={t(`lang_seo:home.page.meta.description`, { day })}
        />
        <meta name="image" content={defaultImage} />
        <meta
          name="og:description"
          content={t(`lang_seo:generic.ogDescription`)}
        />
        <meta name="og:title" content={t("lang_seo:generic.ogTitle")} />
        <meta name="og:site_name" content={t("lang_seo:generic.ogSiteName")} />
        <meta name="og:image" content={defaultImage} />
        <meta name="og:url" content={url} />
        <meta name="fb:app_id" content={`${process.env.FB_ID}`} />
        <link
          rel="preload"
          href="/fonts/body/nextorysans-medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/body/nextorysans-regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/headline/nextorysans-headline-demibold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link rel="canonical" href={url} data-react-helmet="true"></link>
        <script
          id="Cookiebot"
          type="text/javascript"
          src={`https://consent.cookiebot.com/uc.js?cbid=75a257d3-f364-4c99-9c0b-9c181c509196&culture=${tempCode}`}
          defer
        />
      </Head>
      <AppContext.Provider value={props}>
        <div id="newUI" className={styles.app}>
          <main className={styles.main}>
            <HeroBanner
              post={post}
              authKeys={authKeys}
              subscriptions={subscriptions}
              userDetails={userDetails}
              subscriptionInfo={subscriptionInfo}
              signupBook={signupBook}
              offer={true}
            />
            {post?.Video && <HeroVideo source={post?.Video.url} />}
            <Feature spaceTop spaceBottom post={post} />
            {presReleases?.TotalFoundReleases > (presReleases?.Releases?.length || 0) }
            <PressRelease
              presReleases={presReleases}
              post={post}
              morePressReleasesURL={"/investors/pressreleases"}
              onFetchMorePressReleases={() => {}}
              renderMoreReleases={
                presReleases?.TotalFoundReleases > (presReleases?.Releases?.length || 0)
                  ? true
                  : false
              }
            />
          </main>
          <Footer userDetails={userDetails} />
        </div>
      </AppContext.Provider>
      <SchemaMarkup data={getOrganizationSchema(url, locale)} />
    </>
  );
}

// This gets called on every request
 async function _getServerSideProps(context) {
  var ioredis = require("ioredis");

  if (typeof global.redis === "undefined") {
    global.redis = await new ioredis(process.env.REDIS_URL);
  }

  const newContext = {
    ...context,
    redis: global.redis,
  };

  let initialprops = await Common(newContext);
  const serverContext = { ...newContext, ...initialprops };

  let serverprops = await serversideHome(serverContext);
  initialprops = { ...initialprops, ...serverprops };

  return { props: { ...initialprops } };
}

export default Home;


    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  